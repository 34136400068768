import React from "react";
import { PERMATA_LOGO_DARK } from "../../assets/png";
import Styles from "./Styles.module.scss";
import MAP_ICON from "../../assets/png/map-icon-white.png";
import PHONE_ICON from "../../assets/png/phone 1.png";
import EMAIL_ICON from "../../assets/png/email (1) 1.png";
import FB_ICON from "../../assets/svg/facebook.svg";
import IG_ICON from "../../assets/svg/instagram.svg";
import TT_ICON from "../../assets/svg/tiktok.svg";
import YT_ICON from "../../assets/svg/youtube.svg";
import LINKEDIN_ICON from "../../assets/svg/linkedin.svg";
import moment from "moment";
const socialMediaIcon = [
  {
    icon: IG_ICON,
    link: "https://www.instagram.com/permataindonesia_official/",
  },
  {
    icon: FB_ICON,
    link: "https://www.facebook.com/permataindosejahtera/",
  },
  {
    icon: TT_ICON,
    link: "https://www.tiktok.com/@permataindonesia",
  },
  {
    icon: YT_ICON,
    link: "https://www.youtube.com/@permataindonesia1839",
  },
  {
    icon: LINKEDIN_ICON,
    link: "https://www.linkedin.com/company/pt-permata-indonesia/posts/?feedView=all",
  },
];
const contactUs = [
  {
    icon: PHONE_ICON,
    text: "0815 9200022",
  },
  {
    icon: EMAIL_ICON,
    text: "info@permataindonesia.com",
  },
];
export default function Footer() {
  return (
    <div className={Styles.wrapperFooter}>
      <section className={Styles.mainFooter}>
        <div className={Styles.aboutCompany}>
          <div className={Styles.wrapLogo}>
            <img src={PERMATA_LOGO_DARK} alt="permata-logo" />
          </div>
          <div className={Styles.address}>
            <span>Alamat Kami</span>
            <div>
              <img src={MAP_ICON} alt="MAP_ICON" />
              <span className={Styles.addressPoint}>
                Jalan Kyai Madja No.7 Mayestik - Kebayoran Baru, Jakarta Selatan
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.socialMedia}>
          <span className={Styles.follow}>Follow Us</span>
          <div className={Styles.wrapperIcon}>
            {socialMediaIcon.map((item, idx) => (
              <a href={item.link} key={idx} target="_blank" rel="noreferrer">
                <img src={item.icon} alt={item.link} />
              </a>
            ))}
          </div>
        </div>
        <div className={Styles.contactUs}>
          <span className={Styles.textHeader}>Contact Us</span>
          <div className={Styles.wrapperIcon}>
            {contactUs.map((item, idx) => (
              <div key={idx}>
                <img className={Styles.icon} src={item.icon} alt="icon" />
                <span className={Styles.text}>{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className={Styles.bottom}>
        <span className={Styles.copyright}>
          Copyright &copy; {moment().year().toString()} PT Permata Indo
          Sejahtera
        </span>
      </div>
    </div>
  );
}
