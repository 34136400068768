import Styles from "./Styles.module.scss";
import {
  // BackgroundOurCompany,

  IndonesiaGlobe,
  mapicon,
} from "../../assets/png";
import groupmap from "../../assets/png/OurCompany/groupMap.png";
import { useNav } from "../../Hooks/useNav";
const Countries = [
  {
    id: 1,
    name: "Aceh",
  },
  {
    id: 2,
    name: "Medan",
  },
  {
    id: 3,
    name: "Pekanbaru",
  },
  {
    id: 4,
    name: "Batam",
  },
  {
    id: 5,
    name: "Padang",
  },
  {
    id: 6,
    name: "Palembang",
  },
  {
    id: 7,
    name: "Jakarta",
  },
  {
    id: 8,
    name: "Bandung",
  },
  {
    id: 9,
    name: "Semarang",
  },
  {
    id: 10,
    name: "Surabaya",
  },
  {
    id: 11,
    name: "Denpasar",
  },
  {
    id: 12,
    name: "Banjarmasin",
  },
  {
    id: 13,
    name: "Makassar",
  },
  {
    id: 14,
    name: "Manado",
  },
];

export default function Benefit() {
  const aboutRef = useNav("About Us");
  return (
    <div ref={aboutRef} id="About Us">
      <div className={Styles.Container}>
        <div className={Styles.left}>
          <div className={Styles.topLeft}>
            <div className={Styles.LeftMap}>
              <img src={mapicon} className={Styles.IconMap} alt="mapicon" />
            </div>
            <span className={Styles.spanYellow}>Our Company Location</span>
            <span className={Styles.spanH1}>
              Our Company is Spread Across Cities in Indonesia
            </span>
          </div>
          <div className={Styles.bottomLeft}>
            {Countries.map((el) => {
              return (
                <div key={el.id} className={Styles.countries}>
                  {el.name}
                </div>
              );
            })}
          </div>
        </div>

        <div className={Styles.right}>
          <img
            src={groupmap}
            className={Styles.imageGroup}
            alt="IndonesiaGlobe"
            // width="100%"
          />
          <img
            src={IndonesiaGlobe}
            className={Styles.ImgMap}
            alt="IndonesiaGlobe"
            // width="100%"
          />
        </div>
      </div>

      <div className={Styles.containerResponsive}>
        <div className={Styles.top}>
          <div className={Styles.companyLocationBox}>
            <div className={Styles.LeftMap}>
              <img src={mapicon} className={Styles.IconMap} alt="mapicon" />
            </div>
            <span className={Styles.spanYellow}>Our Company Location</span>
          </div>
          <span className={Styles.spanH1}>
            Our Company is Spread Across Cities in Indonesia
          </span>
        </div>

        <div className={Styles.imageMap}>
          <img
            src={groupmap}
            className={Styles.imageGroup}
            alt="IndonesiaGlobe"
            // width="100%"
          />
          <img
            src={IndonesiaGlobe}
            className={Styles.ImgMap}
            alt="IndonesiaGlobe"
            // width="100%"
          />
        </div>

        <div className={Styles.boxBottom}>
          <div className={Styles.bottom}>
            {Countries.map((el) => {
              return (
                <div key={el.id} className={Styles.countries}>
                  {el.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
