import Styles from "./Styles.module.scss";
import { Manufacturing, Handshake, Team } from "../../assets/svg";
import ARROW_DOWN from "../../assets/png/arrow-down.png";
import ARROW_UP from "../../assets/png/arrow-up.png";
import { useState } from "react";
import { useNav } from "../../Hooks/useNav";
// import { FALSE } from "sass";

export default function Benefit() {
  const benefitRef = useNav("Benefit");
  const [showForProvider, setShowProvider] = useState(false);
  const [showForClient, setShowClient] = useState(false);
  const [showForWorkforce, setShowWorkforce] = useState(false);
  return (
    <div ref={benefitRef} className={Styles.Container} id="Benefit">
      <div className={Styles.titleBenefit}>
        <span className={Styles.H1}>BENEFIT FOR ALL</span>
        <span className={Styles.p}>
          Permata Indonesia always optimizes profits for all parties both for
          providers, clients and workers
        </span>
      </div>

      <div className={Styles.dropdownBox}>
        <div className={Styles.listDropdown}>
          <span className={Styles.textDropdown}>For Provider</span>
          {showForProvider ? (
            <img src={ARROW_UP} alt="" onClick={() => setShowProvider(false)} />
          ) : (
            <img
              src={ARROW_DOWN}
              alt=""
              onClick={() => setShowProvider(true)}
            />
          )}
        </div>

        {showForProvider && (
          <div className={Styles.firstBox}>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Supported by a reliable recruitment system
              </span>
            </div>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Transparency</span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Have a recruitment expert</span>
            </div>
          </div>
        )}

        <div className={Styles.listDropdown}>
          <span className={Styles.textDropdown}>For Client</span>
          {showForClient ? (
            <img src={ARROW_UP} alt="" onClick={() => setShowClient(false)} />
          ) : (
            <img src={ARROW_DOWN} alt="" onClick={() => setShowClient(true)} />
          )}
        </div>

        {showForClient && (
          <div className={Styles.secondBox}>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Monitoring the workforce properly
              </span>
            </div>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Easier administrative management
              </span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Efficiency</span>
            </div>
          </div>
        )}

        <div className={Styles.listDropdown}>
          <span className={Styles.textDropdown}>For Workforce</span>
          {showForWorkforce ? (
            <img
              src={ARROW_UP}
              alt=""
              onClick={() => setShowWorkforce(false)}
            />
          ) : (
            <img
              src={ARROW_DOWN}
              alt=""
              onClick={() => setShowWorkforce(true)}
            />
          )}
        </div>

        {showForWorkforce && (
          <div className={Styles.thirdBox}>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>There are many job vacancies</span>
            </div>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Employee benefit program</span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Supported by a system that makes work easy
              </span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Faster recruitment process</span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Transparency</span>
            </div>
          </div>
        )}
      </div>

      <div className={Styles.boxBenefit}>
        <div className={Styles.firstBox}>
          <img src={Manufacturing} alt="manufacturing" />
          <div className={Styles.list}>
            <span className={Styles.forProvider}>For Provider</span>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Supported by a reliable recruitment system
              </span>
            </div>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Transparency</span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Have a recruitment expert</span>
            </div>
          </div>
        </div>

        <div className={Styles.secondBox}>
          <img src={Handshake} alt="handshake" />
          <div className={Styles.list}>
            <span className={Styles.forProvider}>For Client</span>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Monitoring the workforce properly
              </span>
            </div>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Easier administrative management
              </span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Efficiency</span>
            </div>
          </div>
        </div>

        <div className={Styles.thirdBox}>
          <img src={Team} alt="team" />
          <div className={Styles.list}>
            <span className={Styles.forProvider}>For Workforce</span>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>There are many job vacancies</span>
            </div>
            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Employee benefit program</span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>
                Supported by a system that makes work easy
              </span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Faster recruitment process</span>
            </div>

            <div className={Styles.provider}>
              <span className={Styles.logo}>.</span>
              <span className={Styles.text}>Transparency</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
