import Styles from "./Styles.module.scss";
import { TopView } from "../../assets/png";
// import ADDEED_VALUE from "../../assets/png/AddedValueLayout/BackAddedValue.png";

export default function AddedValue() {
  return (
    <>
      <div className={Styles.Container}>
        <div className={Styles.Kotak}>
          <div className={Styles.LeftBody}>
            <div className={Styles.ContentBody}>
              <div className={Styles.tes}>
                <div className={Styles.LeftTitle1}>
                  Trusted Outsourcing Company
                </div>
                <div className={Styles.LeftTitle2} id="AddedValue">
                  ADDED VALUE
                </div>
                <div className={Styles.LeftDesc}>
                  Diverse client-base, national coverage, various
                  jobs/occupation, technology innovation & integration,
                  financially sound, experienced, professional and reliable
                  human resources
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.RightBody}>
            <img
              src={TopView}
              className={Styles.TopView}
              alt="TopView"
              width="100%"
            />
          </div>
        </div>
      </div>

      <div className={Styles.containerResponsive}>
        <div className={Styles.imagesBox}>
          <img src={TopView} alt="added-value" />
        </div>

        <div className={Styles.titleValue}>
          <div className={Styles.topValue}>
            <span className={Styles.trustedText}>
              Trusted Outsourcing Company
            </span>
            <span className={Styles.addedText}>ADDED VALUE</span>
          </div>

          <div className={Styles.ket}>
            Diverse client-base, national coverage, various jobs/occupation,
            technology innovation & integration, financially sound, experienced,
            professional and reliable human resources
          </div>
        </div>
      </div>
    </>
  );
}
