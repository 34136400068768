import React from "react";
import Styles from "./Styles.module.scss";
import RECRUITENT from "../../assets/png/BannerLayout/Illustration.png";
import E_PAYMENT from "../../assets/png/BannerLayout/e-payment.png";
import TRAINING from "../../assets/png/BannerLayout/training.png";
import COACHING from "../../assets/png/BannerLayout/coaching.png";
import INDUCTION from "../../assets/png/BannerLayout/induction.png";
import ADMINISTRATION from "../../assets/png/BannerLayout/induction.png";

const data = [
  {
    id: 1,
    name: "RECRUITMENT",
    desc: "Sourcing and screening interview test, Kerja 365",
    img: RECRUITENT,
  },
  {
    id: 2,
    name: "E-PAYMENT SLIP",
    desc: "Electronic payment slip Kerja 365",
    img: E_PAYMENT,
  },
  {
    id: 3,
    name: "TRAINING",
    desc: "Soft skills, motivational, outbond, Kerja 365",
    img: TRAINING,
  },
  {
    id: 4,
    name: "COACHING",
    desc: "Performance coaching, attitude coaching",
    img: COACHING,
  },
  {
    id: 5,
    name: "INDUCTION",
    desc: "Brief and first entry training, Kerja 365",
    img: INDUCTION,
  },
  {
    id: 6,
    name: "ADMINISTRATION",
    desc: "Contract, payroll, leave, BPJS, termination",
    img: ADMINISTRATION,
  },
];

export default function FeatureBox(props) {
  // const { icon, title, description } = props;
  return (
    <div className={Styles.Container}>
      <div className={Styles.boxFeature}>
        {data?.map((el) => {
          return (
            <div className={Styles.box} key={el.id}>
              <div className={Styles.images}>
                <img src={el.img} alt="" />
              </div>
              <div className={Styles.textBox}>
                <span className={Styles.title}>{el.name}</span>
                <span className={Styles.desc}>{el.desc}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
