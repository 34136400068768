import { useMemo, useRef, useState } from "react";
import { FeatureBox, Header } from "../../components";
import Styles from "./styles.module.scss";
import { useEffect } from "react";
import { useNav } from "../../Hooks/useNav";

export default function BannerLayout() {
  const homeRef = useNav("Home");
  const [bg, setBg] = useState("bg-1");
  const [scrolled, setScrolled] = useState(false);
  const [blur, setBlur] = useState(false);
  const interval = useRef();
  const bannerRef = useRef();

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY === 0) setScrolled(false);
      else setScrolled(true);

      if (bannerRef.current) {
        if (
          bannerRef.current.getBoundingClientRect().bottom <=
          window.innerHeight -
            (bannerRef.current.getBoundingClientRect().height - 80)
        )
          setBlur(true);
        else setBlur(false);
      }
    });
  }, []);

  useEffect(() => {
    interval.current = setTimeout(() => {
      if (bg === "bg-1") setBg("bg-2");
      else if (bg === "bg-2") setBg("bg-3");
      else setBg("bg-1");
    }, 6000);
  }, [bg]);

  const BackgroundMemo = useMemo(() => {
    switch (bg) {
      case "bg-1":
        return Styles.Background_1;
      case "bg-2":
        return Styles.Background_2;
      case "bg-3":
        return Styles.Background_3;
      default:
        return "";
    }
  }, [bg]);

  const onChangeBg = (img) => {
    clearTimeout(interval.current);
    setBg(img);
  };
  return (
    <div
      className={`${Styles.container} ${
        scrolled && Styles.Scrolled
      } ${BackgroundMemo}`}
      ref={bannerRef}
    >
      <Header blur={blur} />
      <div className={Styles.boxHome} ref={homeRef} id="Home">
        <div className={Styles.boxTitle}>
          {bg === "bg-1" && (
            <div className={Styles.left}>
              <span className={Styles.H4}>We Serve With Expertise</span>
              <span className={Styles.H1}>In Outsource Management</span>
              <span className={Styles.p}>
                Delivering manpower outsource, paying agent, system and facility
                outsource business process outsource
              </span>
            </div>
          )}

          {bg === "bg-2" && (
            <div className={Styles.left}>
              <span className={Styles.textSpecialist}>Specialist In</span>
              <span className={Styles.H4}>Outsource Management</span>
              <span className={Styles.H1}>
                Sales, distribution & outsourcing
              </span>
              <span className={Styles.p}>
                Find your precious inside grow your value with us
              </span>
            </div>
          )}

          {bg === "bg-3" && (
            <div className={Styles.left}>
              <span className={Styles.H4}>We Served 200+ Companies</span>
              <span className={Styles.H1}>In Outsource Management</span>
              <span className={Styles.p}>
                Various industries become our partners for more than 16 years
              </span>
            </div>
          )}

          <div className={Styles.Indicator}>
            <div
              onClick={() => onChangeBg("bg-1")}
              className={bg === "bg-1" ? Styles.Active : ""}
            />
            <div
              onClick={() => onChangeBg("bg-2")}
              className={bg === "bg-2" ? Styles.Active : ""}
            />
            <div
              onClick={() => onChangeBg("bg-3")}
              className={bg === "bg-3" ? Styles.Active : ""}
            />
          </div>
        </div>
      </div>
      <div className={Styles.featureBox}>
        <FeatureBox />
      </div>
    </div>
  );
}
