import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import COOPERATIVE_IMAGE from "../../assets/png/cooperative-images.png";
import Styles from "./Styles.module.scss";
import { useNav } from "../../Hooks/useNav";

export default function Values() {
  const valuesRef = useNav("Our Values");
  const [page, setPage] = useState(0);
  const values = useMemo(() => {
    return [
      {
        title: "INTEGRITY",
        description:
          "Secure the quality of being honest and having strong moral principles, consistency of actions, values, methods, measures, principles, expectations, and outcomes",
      },
      {
        title: "TRANSPARENCY",
        description:
          "keep honesty and openness, everybody can see clearly, without anything being hidden",
      },
      {
        title: "RESPECT",
        description:
          "Feeling of deep administration for someone elicited by their abilities, qualities, or achievements shared between two or more people",
      },
    ];
  }, []);
  return (
    <div ref={valuesRef} className={Styles.bodyContainer} id="Our Values">
      <div className={Styles.Container}>
        <div className={Styles.Extender}></div>
        <div className={Styles.Body}>
          <div className={Styles.Background}>
            <div />
            <div />
          </div>
          <div className={Styles.Control}>
            <div className={Styles.Title}>
              <span className={Styles.h1}>Our Values</span>
              <span className={Styles.h3}>We have 3 values from us</span>
            </div>
            <div className={Styles.Content}>
              <div className={Styles.Card}>
                {values.map((each, key) => (
                  <div
                    key={key}
                    className={`${Styles.Body} ${
                      key === page && Styles.Active
                    }`}
                  >
                    <span>{each.title}</span>
                    <span>{each.description}</span>
                  </div>
                ))}
              </div>
              <div className={Styles.Switch}>
                <div
                  onClick={() =>
                    setPage((prev) => (prev !== 0 ? prev - 1 : prev))
                  }
                  className={`${Styles.Button} ${
                    page === 0 && Styles.Disabled
                  }`}
                >
                  <ChevronLeft
                    className={Styles.Icon}
                    sx={{ opacity: page !== 0 ? 1 : 0.2, transition: "400ms" }}
                  />
                </div>
                <div
                  onClick={() =>
                    setPage((prev) =>
                      prev !== values.length - 1 ? prev + 1 : prev
                    )
                  }
                  className={`${Styles.Button} ${
                    page === values.length - 1 && Styles.Disabled
                  }`}
                >
                  <ChevronRight
                    className={Styles.Icon}
                    sx={{
                      opacity: page !== values.length - 1 ? 1 : 0.2,
                      transition: "400ms",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.Illustration}>
            <img src={COOPERATIVE_IMAGE} alt="ILLUSTRATION" />
          </div>
        </div>
      </div>

      <div className={Styles.containerResponsive}>
        <div className={Styles.Extender}></div>
        <div className={Styles.body} id="ourValues">
          <div className={Styles.titleValues}>
            <span className={Styles.span1}>OUR VALUES</span>
            <span className={Styles.span2}>We have 3 values ​​from us</span>
          </div>
          <div className={Styles.imagesValue}>
            <img src={COOPERATIVE_IMAGE} alt="cooperative_image" />
          </div>

          <div className={Styles.Content}>
            <div className={Styles.Card}>
              {values.map((each, key) => (
                <div
                  key={key}
                  className={`${Styles.Body} ${key === page && Styles.Active}`}
                >
                  <span>{each.title}</span>
                  <span>{each.description}</span>
                </div>
              ))}
            </div>
            <div className={Styles.Switch}>
              <div
                onClick={() =>
                  setPage((prev) => (prev !== 0 ? prev - 1 : prev))
                }
                className={`${Styles.Button} ${page === 0 && Styles.Disabled}`}
              >
                <ChevronLeft
                  className={Styles.Icon}
                  sx={{ opacity: page !== 0 ? 1 : 0.2, transition: "400ms" }}
                />
              </div>
              <div
                onClick={() =>
                  setPage((prev) =>
                    prev !== values.length - 1 ? prev + 1 : prev
                  )
                }
                className={`${Styles.Button} ${
                  page === values.length - 1 && Styles.Disabled
                }`}
              >
                <ChevronRight
                  className={Styles.Icon}
                  sx={{
                    opacity: page !== values.length - 1 ? 1 : 0.2,
                    transition: "400ms",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
