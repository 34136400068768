import Styles from "./Styles.module.scss";
import INSURANCE from "../../assets/png/Spesialised/life-insurance.png";
import MEDIA from "../../assets/png/Spesialised/social-media.png";
import CONTRUCTION from "../../assets/png/Spesialised/contruction.png";
import ENERGY from "../../assets/png/Spesialised/energy.png";
import TELECOMUNICATION from "../../assets/png/Spesialised/telecomunicatio.png";
import HEALTH from "../../assets/png/Spesialised/healthcare.png";
import MACHINE from "../../assets/png/Spesialised/machine.png";
import IT from "../../assets/png/Spesialised/it.png";
import BANKING from "../../assets/png/Spesialised/banking.png";
import TRADE from "../../assets/png/Spesialised/trade.png";
// import DEVELOPMENT from "../../assets/png/Spesialised/development.png";
import BUSINESS from "../../assets/png/Spesialised/business.png";
import CLOSE_LOGO from "../../assets/png/close-white.png";
import ARROW_DOWN from "../../assets/png/arrow_downward.png";
import AGRONOMY from "../../assets/png/Spesialised/agronomi.png";
import { useState } from "react";

const dataTop = [
  {
    id: 1,
    img: AGRONOMY,
    name: "Agronomy",
    desc: "Nursery | Maintenance | Harvesting | Grading | Transport Harvest | Landscaper | Gardener",
  },
  {
    id: 2,
    img: INSURANCE,
    name: "Insurance",
    desc: "Admin QC | ASDC Admin | Back Office | Business Service Staff | Call Center Officer | Account Officer | Data Analyst | QA Officer",
  },
  {
    id: 3,
    img: MEDIA,
    name: "Media",
    desc: "Admin Staff | Direct Sales | Finance Officer | HR Officer | Motorist Sales | Relationship Officer | Staff Data Gathering | Team Leader Inbound",
  },
  {
    id: 4,
    img: CONTRUCTION,
    name: "Construction",
    desc: "Drafter | Safety Officer | Building Inspector | Engineering Technician | Product Manager | Supervisor | CAD Data Base Staff",
  },
  {
    id: 5,
    img: ENERGY,
    name: "Energy, Oil & Gas",
    desc: "IT Billing Operation | Analyst Operation | Admin Internal Audit | Admin Product Strategic | Admin Treasury | Admin Pajak | Admin Marketing | Admin Filling Corp Controller",
  },
  {
    id: 6,
    img: TELECOMUNICATION,
    name: "Tele communication",
    desc: "Admin Support | Analyst Operation | Team Leader DCO | Sales Support | Controller MT | Finance Staff | Quality Management | Facilitator",
  },
];

const dataBottom = [
  {
    id: 7,
    img: MACHINE,
    name: "Machine & Auto",
    desc: "Production Engineer | Operation  Production | Repairing Staff | Inventory Executive | Sales Promotion Girl | Supervisor Sales Merchandiser | Team Leader",
  },
  {
    id: 8,
    img: IT,
    name: "Information Technology",
    desc: "Network Operation Center | Network Provisioning | IT Logistic Admin | Junior IT field Support | Junior IT Support | Application Process | IT and EDP | Planning and Design",
  },
  {
    id: 9,
    img: HEALTH,
    name: "Health & Pharmacy",
    desc: "Surveyor | Pharmacist Assistant | Team Leader | Nutritionist | Medical Account Executive | Representative Officer | Relationship Officer | Merchant Excellence",
  },
  {
    id: 10,
    img: BANKING,
    name: "Banking",
    desc: "Content Specialist | Lead Creative Officer | Receptionist | Admin Kredit | Team Manager | Digital Marketing | Marketing Acquisition | Leader Desk Collection",
  },
  {
    id: 11,
    img: TRADE,
    name: "Trade & Distribution",
    desc: "BPO and Policy | Billing Retail Officer | HR Admin | QA IT Development | Validation Officer | Warehouse IKR | Team Leader AE | Government Relations Staff",
  },
  {
    id: 12,
    img: BUSINESS,
    name: "General Business Service",
    desc: "Warehouse Operation | Stock Control | Leader | Receptionist | Supervisor | General Worker | Fleet Service Admin",
  },
];

const dataResponsive = [
  {
    id: 1,
    img: AGRONOMY,
    name: "Agronomy",
    desc: "Nursery | Maintenance | Harvesting | Grading | Transport Harvest | Landscaper | Gardener",
  },
  {
    id: 2,
    img: INSURANCE,
    name: "Insurance",
    desc: "Admin QC | ASDC Admin | Back Office | Business Service Staff | Call Center Officer | Account Officer | Data Analyst | QA Officer",
  },
  {
    id: 3,
    img: MEDIA,
    name: "Media",
    desc: "Admin Staff | Direct Sales | Finance Officer | HR Officer | Motorist Sales | Relationship Officer | Staff Data Gathering | Team Leader Inbound",
  },
  {
    id: 4,
    img: CONTRUCTION,
    name: "Construction",
    desc: "Drafter | Safety Officer | Building Inspector | Engineering Technician | Product Manager | Supervisor | CAD Data Base Staff",
  },
  {
    id: 5,
    img: ENERGY,
    name: "Energy, Oil & Gas",
    desc: "IT Billing Operation | Analyst Operation | Admin Internal Audit | Admin Product Strategic | Admin Treasury | Admin Pajak | Admin Marketing | Admin Filling Corp Controller",
  },
  {
    id: 6,
    img: TELECOMUNICATION,
    name: "Tele communication",
    desc: "Admin Support | Analyst Operation | Team Leader DCO | Sales Support | Controller MT | Finance Staff | Quality Management | Facilitator",
  },
  {
    id: 7,
    img: MACHINE,
    name: "Machine & Auto",
    desc: "Production Engineer | Operation Production | Repairing Staff | Inventory Executive | Sales Promotion Girl | Supervisor Sales Merchandiser | Team Leader",
  },
  {
    id: 8,
    img: IT,
    name: "Information Technology",
    desc: "Network Operation Center | Network Provisioning | IT Logistic Admin | Junior IT field Support | Junior IT Support | Application Process | IT and EDP | Planning and Design",
  },
  {
    id: 9,
    img: HEALTH,
    name: "Health & Pharmacy",
    desc: "Surveyor | Pharmacist Assistant | Team Leader | Nutritionist | Medical Account Executive | Representative Officer | Relationship Officer | Merchant Excellence",
  },
  {
    id: 10,
    img: BANKING,
    name: "Banking",
    desc: "Content Specialist | Lead Creative Officer | Receptionist | Admin Kredit | Team Manager | Digital Marketing | Marketing Acquisition | Leader Desk Collection",
  },
  {
    id: 11,
    img: TRADE,
    name: "Trade & Distribution",
    desc: "BPO and Policy | Billing Retail Officer | HR Admin | QA IT Development | Validation Officer | Warehouse IKR | Team Leader AE | Government Relations Staff",
  },
  {
    id: 12,
    img: BUSINESS,
    name: "General Business Service",
    desc: "Warehouse Operation | Stock Control | Leader | Receptionist | Supervisor | General Worker | Fleet Service Admin",
  },
];

export default function Benefit() {
  const [showDetailTop, setShowDetailTop] = useState("");
  // const [showDetailBottom, setShowDetailBottom] = useState("");
  return (
    <div className={Styles.Container}>
      <div className={Styles.leftBox}>
        <span className={Styles.specialistIn}>Specialist In</span>
        <span className={Styles.outsourcingManagement}>
          Specialised In Outsourcing Management
        </span>
        <span className={Styles.customized}>
          Customized solution to various outsourcing requirement
        </span>
      </div>
      {/* <span>asgdvhasgd {text}</span> */}

      <div className={Styles.rightBox}>
        <div className={Styles.checkDetail}>
          <span className={Styles.click}>Click to show detail</span>
          <img src={ARROW_DOWN} alt="" />
        </div>
        <div className={Styles.optionBox}>
          <div className={Styles.carouselOther}>
            <div className={Styles.otherBox}>
              {dataTop?.map((el) => {
                return (
                  <div
                    className={Styles.card}
                    key={el.id}
                    onClick={() => setShowDetailTop(el.id)}
                  >
                    <div
                      className={`${Styles.imagesBox} ${
                        showDetailTop === el.id && Styles.imagesBoxResponsive
                      }`}
                    >
                      <img src={el.img} alt="" />
                    </div>
                    <div className={Styles.nameBox}>
                      <span className={Styles.name}>{el.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className={`${
              showDetailTop === "" ? Styles.boxDropdownNone : Styles.boxDropdown
            }`}
          >
            {dataTop?.map((el) => {
              return (
                <div key={el.id}>
                  {showDetailTop === el.id && (
                    <div className={Styles.box}>
                      <div className={Styles.nameOption}>
                        <div
                          className={Styles.logoClose}
                          onClick={() => setShowDetailTop("")}
                        >
                          <img src={CLOSE_LOGO} alt="" />
                        </div>
                        <span className={Styles.titles}>
                          {" "}
                          {el.name === "Tele communication"
                            ? "Telecommunication"
                            : el.name}
                        </span>
                      </div>
                      <div className={Styles.descBox}>
                        <span className={Styles.desc}>{el.desc}</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {dataBottom?.map((el) => {
              return (
                <div key={el.id}>
                  {showDetailTop === el.id && (
                    <div className={Styles.box}>
                      <div className={Styles.nameOption}>
                        <div
                          className={Styles.logoClose}
                          onClick={() => setShowDetailTop("")}
                        >
                          <img src={CLOSE_LOGO} alt="" />
                        </div>
                        <span className={Styles.titles}>
                          {el.name === "Tele communication"
                            ? "Telecommunication"
                            : el.name}
                        </span>
                      </div>
                      <div className={Styles.descBox}>
                        <span className={Styles.desc}>{el.desc}</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className={Styles.carouselOther}>
            <div className={Styles.otherBox}>
              {dataBottom?.map((el) => {
                return (
                  <div
                    className={Styles.card}
                    key={el.id}
                    onClick={() => setShowDetailTop(el.id)}
                  >
                    <div
                      className={`${Styles.imagesBox} ${
                        showDetailTop === el.id && Styles.imagesBoxResponsive
                      }`}
                    >
                      <img src={el.img} alt="" />
                    </div>
                    <div className={Styles.nameBox}>
                      <span className={Styles.name}>{el.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.responsiveOption}>
        <div className={Styles.checkDetail}>
          <span className={Styles.click}>Click to show detail</span>
          <img src={ARROW_DOWN} alt="" />
        </div>

        <div className={Styles.optionBox}>
          <div className={Styles.carouselOther}>
            <div className={Styles.otherBox}>
              {dataResponsive?.map((el) => {
                return (
                  <div
                    className={Styles.card}
                    key={el.id}
                    onClick={() => setShowDetailTop(el.id)}
                  >
                    <div
                      className={`${Styles.imagesBox} ${
                        showDetailTop === el.id && Styles.imagesBoxResponsive
                      }`}
                    >
                      <img src={el.img} alt="" />
                    </div>
                    <div className={Styles.nameBox}>
                      <span className={Styles.name}>{el.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className={`${
              showDetailTop === "" ? Styles.boxDropdownNone : Styles.boxDropdown
            }`}
          >
            {dataResponsive?.map((el) => {
              return (
                <div key={el.id}>
                  {showDetailTop === el.id && (
                    <div className={Styles.box}>
                      <div className={Styles.nameOption}>
                        <div
                          className={Styles.logoClose}
                          onClick={() => setShowDetailTop("")}
                        >
                          <img src={CLOSE_LOGO} alt="" />
                        </div>
                        <span className={Styles.titles}>
                          {" "}
                          {el.name === "Tele communication"
                            ? "Telecommunication"
                            : el.name}
                        </span>
                      </div>
                      <div className={Styles.descBox}>
                        <span className={Styles.desc}>{el.desc}</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
