import { Menu } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
// import { Link } from "react-router-dom";
import { PERMATA_LOGO_DARK, PERMATA_LOGO_LIGHT } from "../../assets/png";
import CLOSE_LOGO from "../../assets/png/close.png";
import Styles from "./Styles.module.scss";
import { NavContext } from "../../Context/NavContext";

export default function Header(props) {
  const { mode, blur } = props;

  const { activeLinkId } = useContext(NavContext);

  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  const navLink = [
    {
      id: 1,
      name: "Home",
      code: "Home",
    },
    {
      id: 2,
      name: "Our Values",
      code: "Our Values",
    },
    {
      id: 3,
      name: "Vision & Mission",
      code: "Vision & Mission",
    },
    {
      id: 4,
      name: "About Us",
      code: "About Us",
    },
    {
      id: 5,
      name: "Benefit",
      code: "Benefit",
    },
    // {
    //   id: 6,
    //   name: "Career",
    //   code: "Career",
    // },
    // {
    //   id: 7,
    //   name: "Contact Us",
    //   code: "Contact Us",
    // },
  ];

  const renderLinkNav = (content) => {
    const handleClcikNav = () => {
      document
        .getElementById(content.name)
        .scrollIntoView({ behavior: "smooth" });
    };
    return (
      <div
        className={`${Styles.a} ${
          activeLinkId === content.name && Styles.active
        }`}
        key={content.id}
        onClick={handleClcikNav}
      >
        {content.name}
      </div>
    );
  };

  return (
    <>
      <div className={`${Styles.Container} ${blur && Styles.Blur}`}>
        <div className={Styles.Logo}>
          <img
            src={
              mode === "light" || blur ? PERMATA_LOGO_LIGHT : PERMATA_LOGO_DARK
            }
            alt="PERMATA_LOGO"
          />
        </div>
        <div className={Styles.Menu}>
          {/* <IconButton> */}
          <IconButton onClick={handleClick}>
            <Menu
              sx={{
                color: mode === "light" || blur ? "#011E4B" : "#FFF",
                fontSize: 20,
              }}
            />
          </IconButton>
        </div>
        <div
          className={`${Styles.Nav} ${
            mode === "light" || blur ? Styles.Light : Styles.Dark
          }`}
        >
          {/* <a href="#Benefit">Home</a> */}
          {/* <Link to="#home">Home</Link>
        <Link to="/?Benefit">Services</Link>
        <Link to="/">Our Team</Link>
        <Link to="/">Careers</Link>
        <Link to="/">Contact Us</Link>
        <Link to="/">About Us</Link> */}
          {navLink?.map((el) => renderLinkNav(el))}
        </div>
      </div>

      {isShown && (
        <div className={Styles.Dropdown}>
          <div className={Styles.boxDropdown}>
            <div className={Styles.logoPermata}>
              <img
                src={PERMATA_LOGO_LIGHT}
                alt="permata-logo-light"
                className={Styles.imagesLogo}
              />
              <img
                src={CLOSE_LOGO}
                alt="permata-logo-light"
                className={Styles.imagesClose}
                onClick={() => setIsShown(false)}
              />
            </div>
            <div className={Styles.routesResponsive}>
              {navLink?.map((el) => renderLinkNav(el))}
            </div>
          </div>
          <div
            className={Styles.closeDropdown}
            onClick={() => setIsShown(false)}
          ></div>
        </div>
      )}
    </>
  );
}
