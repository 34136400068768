import Styles from "./styles.module.scss";

const ProductCard = ({ title, content, logo, logoHeight }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        <div className={Styles.content}>
          <div className={Styles.title}>{title}</div>
          <div className={Styles.text}>{content}</div>
        </div>
        <div className={Styles.logo}>
          <img
            className={Styles.img}
            src={logo}
            alt=""
            style={{ maxHeight: logoHeight }}
          />
        </div>
      </div>
      <div className={Styles.bottom}></div>
    </div>
  );
};

export default ProductCard;
