import React, { useMemo } from "react";
import { VisionPillar } from "../../components";
import Styles from "./Styles.module.scss";
import { useNav } from "../../Hooks/useNav";
import IMAGES from "../../assets/png/VisionsLayout/celebration.png";

export default function Visions({ ref }) {
  const visionRef = useNav("Vision & Mission");
  const visions = useMemo(() => {
    return [
      {
        key: "01",
        content:
          "Assist business entity as customer of partner in increasing and developing business through giving service in supplying manpower and business process outsource professionally",
      },
      {
        key: "02",
        content:
          "Assist individual person either as outsource manpower on company employee in increasing standard of living level with technical, non-technical and financial capability",
      },
      {
        key: "03",
        content:
          "Assist investor to develop their business profitable through optimizing performance, system and technology",
      },
      {
        key: "04",
        content:
          "Assist government in developing economy activity, supplying employment and channeling productive manpower",
      },
    ];
  }, []);

  return (
    <div ref={visionRef} id="Vision & Mission" className={Styles.Container}>
      <div className={Styles.visionMission}>
        <span className={Styles.textTop}>OUR VISION & MISSION</span>
        <span className={Styles.textP}>
          Our Vision is Becoming the Best and Professional Outsourcing Company
        </span>
      </div>

      <div className={Styles.contentVisionBox}>
        <div className={Styles.boxImages}>
          <div className={Styles.images}>
            <img src={IMAGES} alt="" />
          </div>
          <div className={Styles.textImages}>
            <span className={Styles.blueText}>
              Our <span className={Styles.yellowText}>4</span> Big Missions
            </span>
            <span className={Styles.litleDesc}>
              We have four missions for our company
            </span>
          </div>
          <div className={Styles.textImagesResponsove}>
            <span className={Styles.blueText}>
              Our <span className={Styles.yellowText}>4</span> Big Missions
            </span>
            <span className={Styles.litleDesc}>
              We have four missions for our company
            </span>
          </div>
        </div>

        <div className={Styles.cardVission}>
          {visions.map((el) => {
            return (
              <div className={Styles.box} key={el.key}>
                <div className={Styles.top}>
                  <span className={Styles.number}>{el.key}</span>
                  <span className={Styles.textDesc}>{el.content}</span>
                </div>
                <div className={Styles.blue}></div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className={Styles.Background}></div>

      <div className={Styles.Control}>
        <div className={Styles.Title}>
          <span className={Styles.h1}>OUR VISION & MISSION</span>
          <span>
            Our Vision is Becoming the Best and Professional Outsourcing Company
          </span>
        </div>

        <div className={Styles.Content}>
          <div className={Styles.Intermezzo}>
            <span className={Styles.Title}>
              Our <span>4</span> Big Missions
            </span>
            <span>We have four missions for our company</span>
          </div>
          <div className={Styles.Pillars}>
            <div className={Styles.Pusher}></div>
            {visions.map((each) => (
              <VisionPillar
                key={each.key}
                serial={each.key}
                content={each.content}
              />
            ))}
            <div className={Styles.Pusher}></div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
