import Styles from "./styles.module.scss";
import { useNav } from "../../Hooks/useNav";
import ProductCard from "../../components/ProductCard";
import TOP_CAREER from "../../assets/png/Product/top-career.png";
import AGROFILIA from "../../assets/png/Product/agrofilia-permata.png";
import GTC from "../../assets/png/Product/gtc.png";
import MOTOR from "../../assets/png/Product/permata-motor.png";
import SKILLS from "../../assets/png/Product/skills.png";
import KERJA365 from "../../assets/png/Product/kerja-365.png";
import PERMATA_OFFICE from "../../assets/png/Product/permata-office.png";

export default function CompanyDiverseSection() {
  const firstRowData = [
    {
      id: 1,
      title: "Agrofilia Permata",
      content:
        "Agrofilia Permata begins its journey in 2022, engaged in agronomy, with the concept of ecogreen.",
      logo: AGROFILIA,
      logoHeight: 75,
    },
    {
      id: 2,
      title: "Permata Motor",
      content:
        "Permata Motor or PT Permata Auto Indonesia begins its journey in 2005 as a dealer motor Yamaha and Generator.",
      logo: MOTOR,
      logoHeight: 44,
    },
    {
      id: 3,
      title: "Global Top Career",
      content:
        "Global Top Career begins in 2010 as an business entity focuses on Learning and Development, Management Consultancy, Media Publising.",
      logo: GTC,
      logoHeight: 62,
    },
  ];

  const secondRowData = [
    {
      id: 1,
      title: "Topcareer",
      content:
        "Topcareer begins its journey on Media Publishing – Web Portal in 2011. The product known as Topcareer.id which has been leading in career and work topic media.",
      logo: TOP_CAREER,
      logoHeight: 32,
    },
    {
      id: 2,
      title: "Skills.id",
      content:
        "Skills.id begins its journey in 2018 under PT Global Retail Bersama focused on Digital Market Places.",
      logo: SKILLS,
      logoHeight: 40,
    },
    {
      id: 3,
      title: "Kerja365",
      content:
        "Kerja365 under PT Permata Indo Sejahtera begins its journey in 2019 as a Digital Outsourcing Platform.",
      logo: KERJA365,
      logoHeight: 49,
    },
  ];

  const mobileData = [
    {
      id: 1,
      title: "Agrofilia Permata",
      content:
        "Agrofilia Permata begins its journey in 2022, engaged in agronomy, with the concept of ecogreen.",
      logo: AGROFILIA,
      logoHeight: 75,
    },
    {
      id: 2,
      title: "Permata Motor",
      content:
        "Permata Motor or PT Permata Auto Indonesia begins its journey in 2005 as a dealer motor Yamaha and Generator.",
      logo: MOTOR,
      logoHeight: 44,
    },
    {
      id: 3,
      title: "Global Top Career",
      content:
        "Global Top Career begins in 2010 as an business entity focuses on Learning and Development, Management Consultancy, Media Publising.",
      logo: GTC,
      logoHeight: 62,
    },
    {
      id: 4,
      title: "Topcareer",
      content:
        "Topcareer begins its journey on Media Publishing – Web Portal in 2011. The product known as Topcareer.id which has been leading in career and work topic media.",
      logo: TOP_CAREER,
      logoHeight: 32,
    },
    {
      id: 5,
      title: "Skills.id",
      content:
        "Skills.id begins its journey in 2018 under PT Global Retail Bersama focused on Digital Market Places.",
      logo: SKILLS,
      logoHeight: 40,
    },
    {
      id: 6,
      title: "Kerja365",
      content:
        "Kerja365 under PT Permata Indo Sejahtera begins its journey in 2019 as a Digital Outsourcing Platform.",
      logo: KERJA365,
      logoHeight: 49,
    },
  ];

  const careerRef = useNav("Career");
  return (
    <div ref={careerRef} className={Styles.bodyCareer} id="Career">
      <div className={Styles.container}>
        <div className={Styles.descBox}>
          <div className={Styles.information}>
            <span className={Styles.H1}>Discover Our Group of Company</span>
            <span className={Styles.p}>
              Various businesses owned by Permata Indonesia
            </span>
          </div>
          <div className={Styles.imageWrapper}>
            <img className={Styles.img} src={PERMATA_OFFICE} alt="" />
            <div className={Styles.blueBoxesWrapper}>
              <div className={Styles.topBox}></div>
              <div className={Styles.bottomBox}></div>
            </div>
          </div>
        </div>
        <div className={Styles.mobileContainer}>
          <div className={Styles.cards}>
            {mobileData?.map((el) => (
              <ProductCard
                key={el?.id}
                title={el?.title}
                content={el?.content}
                logo={el?.logo}
                logoHeight={el?.logoHeight}
              />
            ))}
          </div>
        </div>
        <div className={Styles.cardContainer}>
          <div className={Styles.cards}>
            {firstRowData?.map((el) => (
              <ProductCard
                key={el?.id}
                title={el?.title}
                content={el?.content}
                logo={el?.logo}
                logoHeight={el?.logoHeight}
              />
            ))}
          </div>
          <div className={Styles.cards}>
            {secondRowData?.map((el) => (
              <ProductCard
                key={el?.id}
                title={el?.title}
                content={el?.content}
                logo={el?.logo}
                logoHeight={el?.logoHeight}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
