// import React from 'react'

import { useContext, useEffect, useRef } from "react";
import { useOnScreen } from "./useOnScreen";
import { NavContext } from "../Context/NavContext";

export const useNav = (navlinkId) => {
  const ref = useRef(null);
  const { setActiveLinkId } = useContext(NavContext);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) {
      setActiveLinkId(navlinkId);
    }
  }, [onScreen, setActiveLinkId, navlinkId]);
  return ref;
};
